<template>
	<div class="w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y:scroll">
		<BHLoading :show="loading" />
		<a-modal :width="'600px'" :title="title" centered :visible="showFieldModal" okText="CREATE" @cancel="cancelField" @ok="submitField">
			<a-row :gutter="16">
				<a-form-model ref="newField" :model="newField">
					<a-col :span="24">
						<a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
							<a-input v-model="newField.name" size="large" placeholder="Enter the Name"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" class="mb-4">
						<a-checkbox v-model="newField.required">Required</a-checkbox>
					</a-col>
					<a-col :span="24">
						<a-form-model-item prop="type" label="Field Type" required
							:rules="req('Please choose a type for your field')">
							<a-select @change="typeSelected" v-model="newField.type" size="large">
								<a-select-option v-for="t in types" :key="t.value">{{ t.label }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" v-if="newField.type == 'multiplechoice'">
						<a-form-model-item>
							<a-checkbox v-model="multiple">Allow Multiple Answers</a-checkbox>
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<template v-if="newField.type === 'multiplechoice' || newField.type === 'checkbox'">
							<a-form-model-item v-for="(option, optionI) in options" :key="optionI"
								:label="`Options #${optionI + 1}`">
								<div class="dF aC" style="justify-content:space-between">
									<a-input @change="changeOption(option, optionI)" v-model="option.text"
										class="flex-1"></a-input>

									<a-icon class="ml-2" v-if="optionI == options.length - 1"
										@click="addOptions(options[options.length - 1])"
										style="font-size:25px; color: var(--orange)" type="plus-circle" />
									<a-icon class="ml-2" v-if="options.length > 2 && optionI != options.length - 1"
										@click="options.splice(optionI, 1)" style="font-size:25px; color: var(--orange)"
										type="minus-circle" />
								</div>
							</a-form-model-item>
						</template>
					</a-col>
					<a-col :span="24" v-if="newField.type == 'text'">
						<a-form-model-item prop="value" label="Default Value">
							<a-input v-model="newField.value" size="large" placeholder="Enter the Default Value"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" v-else-if="newField.type == 'number'">
						<a-form-model-item prop="value" label="Default Value">
							<a-input type="number" v-model="newField.value" size="large"
								placeholder="Enter the Default Value" :default-value="0"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" v-else-if="newField.type === 'multiplechoice' || newField.type === 'checkbox'">
						<a-form-model-item prop="value" label="Default Value">
							<a-select v-model="newField.value" size="large" placeholder="Select a Default Value"
								:mode="newField.type == 'checkbox' ? '' : newField.type == 'multiplechoice' && multiple ? 'multiple' : ''">
								<a-select-option v-for="(value, valueI) in filterOptions(options)" :key="valueI"
									:value="valueI">{{ value.text }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" v-else-if="newField.type === 'date'">
						<a-form-model-item prop="value" label="Default Value">
							<a-date-picker style="width:100%" v-model="newField.value" size="large"
								placeholder="Select a Default Value" />
						</a-form-model-item>
					</a-col>
				</a-form-model>
			</a-row>
		</a-modal>
	</div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import moment from 'moment'

export default {
	components: { BHLoading },
	props: {

	},
	data() {
		return {
			loading: false,
			title: 'Add Custom Field',
			newField: {
				name: '',
				required: false,
				type: 'text',
				value: ''
			},
			types: [
				{ value: 'text', label: 'Text' },
				{ value: 'number', label: 'Number' },
				{ value: 'checkbox', label: 'Check Box' },
				{ value: 'multiplechoice', label: 'Multiple Choice' },
				{ value: 'date', label: 'Date' }
			],
			options: [{
				text: ''
			}],
			multiple: false,
		}
	},
	computed: {
		dateFormat() {
			return this.$store.state.siteplan.allSettings.user && this.$store.state.siteplan.allSettings.user.options && this.$store.state.siteplan.allSettings.user.options.regional && this.$store.state.siteplan.allSettings.user.options.regional.dateFormat ? this.$store.state.siteplan.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
		customFields() {
			return Object.values(this.$store.state.siteplan.customFields)
		},
		user() {
			return this.$store.state.user.user
		},
		showFieldModal() {
			return this.$store.state.siteplan.customFieldModal
		}
	},
	methods: {
		moment,
		req: msg => ({ required: true, message: msg }),

		filterOptions(arr) {
			return arr.filter(x => x.text != '')
		},

		changeOption(text, index) {
			if (text.text.length == 0) {
				this.options.splice(index, 1)
			}
		},

		addOptions(lastOption) {
			if (!lastOption.text) return this.$message.error('You need to fill in the field before adding a new one')
			else {
				let option = {
					text: ''
				}
				this.options.push(option)
			}
		},

		typeSelected(e) {
			if (e == 'text') {
				this.newField.value = ''
			} else if (e == 'number') {
				this.newField.value = 0
			} else if (e == 'checkbox' || e == 'multiplechoice') {
				this.newField.value = []
			} else if (e == 'date') {
				this.newField.value = this.moment(Date.now())
			}
		},

		cancelField() {
			this.$refs['newField'].resetFields()
			this.newField = {
				name: '',
				required: false,
				type: 'text',
				value: '',
			};
			this.title = 'Add Custom Field'
			this.$store.commit('UPDATE_CUSTOM_FIELD_MODAL', false)
			this.options = [{
				text: ''
			}]
		},

		submitField() {
			this.$refs.newField.validate(valid => {
				if (valid) {
					let sendObj = { name: '', options: {}, type: '', required: false }
					this.loading = true
					if (this.newField.type === 'multiplechoice' || this.newField.type === 'checkbox') {
						if (this.options.length == 1 || (this.options[1].text == '' && this.options.length == 2)) {
							this.loading = false
							return this.$message.error('Requires at least two Choices')
						}
						sendObj.name = this.newField.name
						sendObj.type = this.newField.type
						sendObj.options = {
							default: this.newField.value,
							multipleChoices: this.options,
							multipleAnswers: this.multiple,
							required: this.newField.required
						}
						sendObj.id = this.newField.id
						sendObj.options.multipleChoices = sendObj.options.multipleChoices.map(x => x = x.text)
						sendObj.user = this.user.id

						if (this.title == 'Add Custom Field') {
							sendObj.for = 'lot'
							this.$api.post(`/custom-fields/:instance`, sendObj).then(({ data }) => {
								this.$store.commit('SET_PROP', { where: ['customFields', data.id], what: data })
								this.loading = false
								this.cancelField()
							}).catch(err => {
								this.$message.error(this.$err(err))
								this.loading = false
								this.cancelField()
							})
						}
					} else if (this.newField.type == 'text' || this.newField.type == 'number' || this.newField.type == 'date') {
						sendObj.id = this.newField.id
						sendObj.name = this.newField.name
						sendObj.type = this.newField.type
						sendObj.options = {
							default: this.newField.value,
							multipleChoices: [],
							multipleAnswers: false,
							required: this.newField.required
						}
						sendObj.user = this.user.id

						if (this.title == 'Add Custom Field') {
							sendObj.for = 'lot'
							this.$api.post(`/custom-fields/:instance`, sendObj).then(({ data }) => {
								this.$store.commit('SET_PROP', { where: ['customFields', data.id], what: data })
								this.loading = false
								this.cancelField()
							}).catch(err => {
								this.$message.error(this.$err(err))
								this.loading = false
								this.cancelField()
							})
						}
					}
				}
			})
		}
	}
}
</script>

<style>
</style>
